import CryptoJS from "crypto-js";

export const decryptData = (encryptedData) => {
  if (!encryptedData) return null;
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  const originalData = bytes.toString(CryptoJS.enc.Utf8);
  return originalData;
};

export const encryptData = (data) => {
  if (!data) return null;
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const encryptedData = CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
  return encryptedData;
}; 