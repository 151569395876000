import React, { Suspense } from 'react';
import "./assets/scss/main.scss";
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './routes/ProtectedRoute';
import { mediaBreakpoint } from "./componets/global/MediaBreakPointes";
import { Loader } from 'semantic-ui-react';

const HomeScreen = React.lazy(() => import('./componets/screens/HomeScreen'));
const Login = React.lazy(() => import('./componets/Login'));
const ForgetPassword = React.lazy(() => import('./componets/ForgotPassword'));
const Error401 = React.lazy(() => import("./componets/errorScreens/Error401"));
const Error404 = React.lazy(() => import("./componets/errorScreens/Error404"));
const Error500 = React.lazy(() => import("./componets/errorScreens/Error500"));
const Dashboard = React.lazy(() => import('./componets/screens/Dashboard'));
const TicketCreation = React.lazy(() => import('./componets/screens/TicketCreation'));
const TicketListScreen = React.lazy(() => import('./componets/screens/TicketListScreen'));
const Footer = React.lazy(() => import('./componets/global/Footer'));
const Notification = React.lazy(() => import('./componets/screens/Notification'));
const ProfileScreen = React.lazy(() => import('./componets/screens/ProfileScreen'));
const BusinessList = React.lazy(() => import('./componets/screens/BusinessList'));
const UserListScreen = React.lazy(() => import('./componets/screens/UserListScreen'));
const BusinessCreation = React.lazy(() => import('./componets/screens/BusinessCreation'));
const BusinessDetailed = React.lazy(() => import('./componets/screens/BusinessDetailed'));
const OrganisationUserList = React.lazy(() => import('./componets/screens/OrganisationUsersList'));
const ArthTattvaUserManagement = React.lazy(() => import('./componets/screens/ArthTattvaUserManagement'));
const TicketManagement = React.lazy(() => import('./componets/screens/TicketManagement'));
const TicketDetails = React.lazy(() => import('./componets/screens/TicketDetails'));
const SystemActivityPage = React.lazy(() => import('./componets/screens/SystemActivityPage'));
const EmailServerConfPage = React.lazy(() => import('./componets/screens/EmailServerConf'));
const FileManagementAdmin = React.lazy(() => import('./componets/screens/FileManagementConf'));

const LoadingBar = () => (
  <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Loader active inline="centered" size="large" />
  </div>
);



const App = () => {
  const isResponsive = mediaBreakpoint?.mobile > window.innerWidth;

  return (
    <Router>
      <AuthProvider>
        <RoutesWrapper isResponsive={isResponsive} />
      </AuthProvider>
    </Router>
  );
};

const RoutesWrapper = ({ isResponsive }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const matched = currentPath !== "/login" && currentPath !== "/forgetPassword" ;
  const params = useParams();
  console.log(params, 'id in app');
  
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgetPassword/:reset?" element={<ForgetPassword />} />
          <Route path="/unauthorized" element={<Error401 />} />
          <Route path="/500" element={<Error500 />} />
          <Route path="*" element={<Error404 />} />

          <Route 
            path="/" 
            element={
              <ProtectedRoute>
                <HomeScreen />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/createTicket/:id?" 
            element={
              <ProtectedRoute>
                <TicketCreation />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/ticketList/:id?/:ticketId?" 
            element={
              <ProtectedRoute>
                <TicketListScreen />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/notification" 
            element={
              <ProtectedRoute>
                <Notification />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/profile" 
            element={
              <ProtectedRoute>
                <ProfileScreen />
              </ProtectedRoute>
            } 
          />
           <Route 
            path="/businessList" 
            element={
              <ProtectedRoute>
                <BusinessList />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/usersList" 
            element={
              <ProtectedRoute>
                <UserListScreen />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/businessCreation/" 
            element={
              <ProtectedRoute requiredRole="superuser">
                <BusinessCreation />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/businessDetail/:BuisnessId?" 
            element={
              <ProtectedRoute requiredRole="superuser">
                <BusinessDetailed />
              </ProtectedRoute>
            } 
          />         
           <Route 
          path="/ticketDetails/:ticketId?" 
          element={
            <ProtectedRoute requiredRole="superuser">
              <TicketDetails />
            </ProtectedRoute>
          } 
        />
                   <Route 
          path="/SystemActivityPage" 
          element={
            <ProtectedRoute requiredRole="superuser">
              <SystemActivityPage />
            </ProtectedRoute>
          } 
        />

        <Route 
          path="/EmailServerPage" 
          element={
            <ProtectedRoute requiredRole="superuser">
              <EmailServerConfPage />
            </ProtectedRoute>
          } 
        />

       <Route 
          path="/FileManagement" 
          element={
            <ProtectedRoute requiredRole="superuser">
              <FileManagementAdmin />
            </ProtectedRoute>
          } 
        />
          <Route 
            path="/organisationUsers" 
            element={
              <ProtectedRoute requiredRole="superuser">
                <OrganisationUserList />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/arthtatavaUsers" 
            element={
              <ProtectedRoute requiredRole="superuser">
                <ArthTattvaUserManagement />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/ticketManagement" 
            element={
              <ProtectedRoute requiredRole="superuser">
                <TicketManagement />
              </ProtectedRoute>
            } 
          /> 
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute requiredRole="superuser">
                <Dashboard />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </Suspense>
      {(isResponsive && matched) && (
        <Suspense fallback={<LoadingBar />}>
          <Footer id={params} />
        </Suspense>
      )}
    </>
  );
};

export default App;
