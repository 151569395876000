import Axios from "axios";
import ls from "local-storage";
import { restbaseurl, cdnurl } from "./constants";
import { updateUserInfo, clearCookies } from "./index";
import { encryptData } from "./cryptoUtils";

class RestDataSource {
  static async SendRequest(method, url, callback, data = {}, urlType = "api") {
    try {
      console.log(restbaseurl, 'restbaseurl--->', urlType, cdnurl);
      const token = ls.get('access_token')?.data;       
      const req = Axios.request({
        baseURL: restbaseurl,
        method,
        url,
        data,
        headers: {
          'ngrok-skip-browser-warning': 'true',
          'Authorization': token ? `Bearer ${token}` : '',
        },
        withCredentials: true,
      });

      const resp = await req;
      const respdata = resp.data;
      if (resp.status === 200) {
        const { msg, data } = respdata; 
        
        if (msg === "success") {        
          if (
            String(url).search("/api/auth/login") !== -1
          ) {
            if(Object.keys(data)?.length > 0){
              const role = encryptData(data?.role);
              console.log(role, 'role-------role--->>');
              updateUserInfo(data.access_token, role, data?.user_id, data?.businesses);
            } else {
              console.log("invalid")
            }
          } else {
            const cookieObj = ls.get("access_token");
            if (cookieObj !== undefined && cookieObj !== null) {
              const now = new Date();
              if (now.getTime() <= cookieObj.expiry) {
                cookieObj.expiry = now.getTime() + 1209600000;
                ls.set("access_token", cookieObj);
              } else {
                ls.remove("access_token");
                ls.remove("userName");
              }
            }
          }
        } else {
          console.log("Regular flow");
        }
      }
      if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
          callback(resp.data);
      } else {
        // callback(resp.data);
        callback({...resp.data, type: 'error'});
      }
    } catch (err) {
      console.error('API request failed:', err);
      if (err.response) {
          const status = err.response.status;
          const msg = err.response.data?.msg || 'An error occurred';
          if (status === 401) {            
              if (msg === "Invalid token.") {
                  clearCookies();
                  document.location.href = "/login";
              } else {
                  // localStorage.deleteItem("access_token");
                  clearCookies()
                  document.location.href = "/login";
                  // document.location.href = "/unauthorized";
              }
          }
          else if (status === 500) {
              // document.location.href = "/500";
          } else {
            callback({...err.response, type: 'error'});
          }
      } else {
          console.error('Network Error:', err.msg);
      }
  }
  
  }

  static async GetData(
    callback,
    url,
    data = {},
    method = "GET",
    urlType = "api"
  ) {
    console.log(method, 'method');
    
    RestDataSource.SendRequest(method, url, callback, data, urlType);
  }

  static async Save(callback, url, data) {
    RestDataSource.SendRequest("post", url, callback, data);
  }

  static async Update(callback, url, data) {
    RestDataSource.SendRequest("put", `${url}/${data.id}`, callback, data);
  }

  static async Delete(callback, url, data) {
    RestDataSource.SendRequest("delete", `${url}/${data.id}`, callback, data);
  }

  static async Put(callback, url, data) {
    RestDataSource.SendRequest("put", url, callback, data);
  }
}

export default RestDataSource;
