
export const resturls = {
    login: "/api/auth/login/",
    forgotPassword: "/api/auth/forgot-password/",
    verifyOtp: "/api/auth/verify-otp/",
    resetPassword: "/api/auth/reset-password/",
    checkTokenValidity: "/api/auth/token-validation/",
    uploadFile: "/api/file/",
    getTeamList: "/api/business-users/",   
    createTicket: "/api/ticket/",
    getBusinesses: "/api/get-businesses",
    getTickets: "/api/get-tickets/",
    addNewDocuments: "/api/document/",
    addComment: "/api/comment/",
    ticketList: "/api/ticket/",
    obtainCategortList: "/api/ticket-category/",
    obtainUsersList: "/api/business-users/",
    notificationRead: "/api/mark-notification-read/",
    obtainLogDownloadLink: "/api/logs/ticket-logs",
    obtainOverallDownloadLink: "/api/logs/ticket/business/",
    obtainCategoryWiseUser: "/api/get-all-types-users",
    createBusiness: "/api/business/",
    createUser: "/api/auth/generate-temp-creds/",
    obtainSubCategory: "/api/ticket-category/",
    file: "/api/file/",
    admin_stats:"/api/admin-stats",
    users: "/api/auth/user/",
    ticketTimelines:"/api/ticket-timelines",
    logEntry: "/api/log-entry",
    obtainTicketDownloadLink: "/api/ticket-timelines-download",
    obtainTimelineList: "api/ticket-timeline/",
    entryLogDownload: "/api/entry-logs-download",
    fetchBusinessType:"api/business-type/",
    fileFilter: "/api/file",
    filesDownload: "api/download-files-excel",
    profilefetch: '/api/auth/user',
    emailserver:'/api/auth/smtp-config/',
    emailserverDownload: "/api/download-email-server-excel"
};
